<template>
  <section class="container-fluid" id="main-core">
    <div class="row mcas">
      <div class="col-md-6 col-lg-4 main" style="background: white;">

        <div class="row" id="warea">
          <div class="col-12">
            <div class="row walifo">
              <div class="col-6 xtl" style="color: black;">
                <div class="mt-1 mb-2 tf-16">Balance</div>
                <div class="mt-1 mb-2 tfcdb tfw-6 tffss tf-18 tfwr ddavc" style="color: black;"><span class="tf-24 tfw-7"
                    id="">{{ this.balance }}</span><span class="pr-2"><img class="gisv" id="lhsd" @click="reload()"
                      src="../assets/includes/icons/refresh_small.png"></span></div>
                <div class="mt-1 tf-16 tfcdg" style="color: black;">ID:<span id="u_id">{{ this.id }}</span></div>
              </div>
              <div class="col-6 pr-1 jcrdg">
                <div class="rc-wal" onclick="window.location.href='#/recharge'">Recharge</div>
                <div class="wd-bal" onclick="window.location.href='#/withdrawal'">Withdraw</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-56">
            <div class="row tf-12 tfcdb tfw-7 1wtj0ep pbt-18">
              <div class="col-6 pdr5">
                <div class="taskR" style="background: white;" onclick="window.location.href='#/taskReward'"><img
                    src="../assets/includes/icons/TaskR.png" height="36"><span class="pl-10" style="color: black;">Task
                    reward</span></div>
              </div>
              <div class="col-6 pdl5">
                <div class="CheckR" style="background: white;" onclick="window.location.href='#/CheckIn'"><img
                    src="../assets/includes/icons/CheckR.png" height="36"><span class="pl-10" style="color: black;">Check
                    in</span></div>
              </div>
              <div class="col-12" onclick="window.location.href='#/MyLink'" style="margin-bottom: 10px;"><img
                  src="../assets/includes/images/home_banner.gif"
                  style="width: 100%; border-radius: 25px;  box-shadow: #98797959 0px 0px 10px 1px;"></div>
              <div v-for="game in games_data" :key="game.name" class="col-6" style="justify-content: space-evenly;">
                <div class="icard" @click="goToGame(game)">
                  <div v-if="game.maintenance == true" class="comsoon">Maintainence</div>
                  <img :src="'https://indiawin.fun/images/' + game.image">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="odrea"></div>
        <div class="row" id="footer">
          <div class="col-12 nav-bar adsob" id="adsob">
            <div class="row">
              <div class="col-3 pa-0">
                <div class="navItem sel" id="moxht2b4u" onclick="window.location.href='#/'">
                  <i class="bi bi-house-door-fill"></i>
                  <div class="xtc" style="font-size: 14px;">Home</div>
                </div>
              </div>
              <div class="col-3 pa-0">
                <div class="navItem" id="raeiyf2m0" onclick="window.location.href='#/promotion'">
                  <i class="bi bi-person-plus-fill"></i>
                  <div class="xtc" style="font-size: 14px;">Invite</div>
                </div>
              </div>
              <div class="col-3 pa-0">
                <div class="navItem" id="sfrm6bvy" onclick="window.location.href='#/recharge'">
                  <i class="bi bi-wallet-fill"></i>
                  <div class="xtc" style="font-size: 14px;">Recharge</div>
                </div>
              </div>
              <div class="col-3 pa-0">
                <div class="navItem" id="mcpnvd2my" onclick="window.location.href='#/mine'">
                  <i class="bi bi-person-fill"></i>
                  <div class="xtc" style="font-size: 14px;">My</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="note">
        </div>
        <div class="row" id="anof">
          <div class="col-12 conod" @click="clink()" id="clink">
            <div class="ssmg banner flex fadein" id="smgid">
              <div class="xtc pt-2 pb-2"><img src="../assets/includes/images/banner.png" style="
                      width: 100%;
                  "></div>
            </div>
          </div>
        </div>
        <div class="row" id="dta_ref"></div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "HomeView",
  data() {
    return {
      count: 1,
      id: null,
      username: null,
      balance: null,
      Users: [],
      games_data: []

    }
  },
  beforeCreate: function () {
    if (localStorage.getItem("username") == null) {
      this.$router.push({ name: "login" });
    }
    axios
      .get("https://indiawin.fun/indiawin/src/api/game_settings.php")
      .then((res) => {
        console.log(res.data[0].games.games);
        this.games_data = res.data[0].games.games;
        this.games_data = this.games_data.filter(game => game.show === true);
      });
  },

  beforeUnmount: function () {
    clearInterval(this.repeat);
  },
  mounted: function () {
    this.check();
    this.getUserdetails();
  },
  computed: {
    randomDivCount() {
      return Math.floor(Math.random() * 5) + 1;
    },
  },
  methods: {

    goToGame(game) {
      if (game.maintenance != true) {
        this.$router.push(game.route.substring(1));
      }
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getRandomAmount() {
      return `₹${Math.floor(Math.random() * 9999 + 99)}`;
    },
    getRandomGame() {
      const games = ["Fast Parity", "Parity", "Dice", "Andar Bahar", "Circle", "Jetx"];
      return games[Math.floor(Math.random() * games.length)];
    },
    check() {
      if (localStorage.getItem("note") == "true") {
        document.getElementById("clink").style.display = "none";
      } else {
        document.getElementById("clink").style.display = "none";
        console.log(localStorage.getItem("note"));
      }
    },
    clink() {
      document.getElementById("clink").style.display = "none";
      localStorage.setItem("note", true);
    },
    reload() {
      if (document.getElementById("lhsd")) {
        document.getElementById("lhsd").classList.add("wals");
        this.getUserdetails();
        setTimeout(function e() {
          document.getElementById("lhsd").classList.remove("wals");
        }, 1000);
      }
    },
    getUserdetails() {
      if (document.getElementById("lhsd")) {

        document.getElementById("lhsd").classList.add("wals");
        setTimeout(function e() {
          document.getElementById("lhsd").classList.remove("wals");
        }, 1000);

        this.username = localStorage.getItem("username");
        axios
          .get(
            "https://indiawin.fun/indiawin/src/api/me.php?action=getuserinfo&user=" +
            this.username, {
            headers: {
              'token': localStorage.getItem('token'),
              'username': localStorage.getItem('username')
            }
          }
          )
          .then((res) => {
            if (res.data.message == "Unauthorized") {
              localStorage.removeItem("username");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
            console.log(res.data);
            this.Users = res.data.user_Data;
            this.id = this.Users[0].id;
            this.balance = this.Users[0].balance;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getUserdetails();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getUserdetails();
    next();
  }
}
</script>
